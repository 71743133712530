import React from "react"

import CustomersList from "../components/CustomersList";

const CustomersPage = () => {
  return null

  if (typeof window === "undefined") return null

  return <CustomersList />
}

export default CustomersPage
