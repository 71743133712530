import React from "react"
import SelectColumnFilter from "../Table/SelectColumnFilter"
import Flag from "../UI/Flag"
import { Link } from "gatsby"
import SubscriptionStatus from "../UI/SubscriptionStatus"
import { CountryCodeCell } from "./index.styles"

function StatusCell({ cell: { value } }) {
  return <SubscriptionStatus status={value} />
}

const useTableSettings = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        aggregate: ["sum", "uniqueCount"],
        Cell: StatusCell,
        Filter: SelectColumnFilter,
        filter: "includes",
        Aggregated: state => (
          <SubscriptionStatus status={state.row.subRows[0].original.status} />
        ),
      },
      {
        Header: "Parent Email",
        accessor: "parent",
        aggregate: ["sum", "uniqueCount"],
        Cell: ({ cell: { value } }) => (
          <Link to={`/customer/${value}`}>{value}</Link>
        ),
        filter: "fuzzyText",
        isGrouped: true,
        Aggregated: ({ cell: { value } }) => `${value} Unique Names`,
      },
      {
        Header: "Country",
        accessor: "countryCode",
        aggregate: ["sum", "uniqueCount"],
        Cell: ({ cell: { value } }) => (
          <CountryCodeCell>
            <Flag countryCode={value} />
            {value}
          </CountryCodeCell>
        ),
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) =>
          groupByID === "parent" || subRows.length === 1 ? (
            <CountryCodeCell>
              <Flag countryCode={subRows[0].original.countryCode} />
              {subRows[0].original.countryCode}
            </CountryCodeCell>
          ) : (
            `${subRows.length} Unique Names`
          ),
      },
      {
        Header: "Plan",
        accessor: "plan",
        aggregate: ["sum", "uniqueCount"],
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) =>
          groupByID === "parent" || subRows.length === 1
            ? subRows[0].original.plan
            : `${subRows.length} Unique Names`,
      },
      {
        Header: "Renew",
        accessor: "renewal",
        Aggregated: ({
          cell: {
            row: { groupByID, subRows },
          },
        }) =>
          groupByID === "parent" || subRows.length === 1
            ? subRows[0].original.renewal
            : `${subRows.length} Unique Names`,
      },
      {
        Header: "Age",
        accessor: "age",
        canGroupBy: false,
        Cell: ({ cell: { value } }) => <div>{value}d</div>,
        Aggregated: state => <div>{state.row.subRows[0].original.age}d</div>,
      },
    ],
    []
  )

  return {
    columns,
  }
}

export default useTableSettings
