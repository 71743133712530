import styled from 'styled-components'

export const CountryCodeCell = styled.div`
  > :first-child {
    margin-right: 10px;
  }
  > :last-child {
    display: flex;
    align-items: center;
  }
`

export const Actions = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  > :nth-child(n) {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0;
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  > :nth-child(1) {
    height: 28px;
    width: 28px;
    position: relative;
    margin-right: 10px;
  }
  > :nth-child(2) {
    padding-top: 5px;
    font-size: 12px;
  }
`
